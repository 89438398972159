import type { FC, PropsWithChildren } from 'react'
import styled from '@emotion/styled'

import CopyTextBase, * as CopyText from '@segment/matcha/recipes/CopyText'
import HeroBase, * as HeroRecipe from '@segment/matcha/recipes/Hero'

import DemoForm from '@segment/templates/Forms/DemoForm'

import heroPng2x from './assets/HeroCustomerDataPlatform@2x.png'
import heroWebp from './assets/HeroCustomerDataPlatform.webp'
import heroWebp2x from './assets/HeroCustomerDataPlatform@2x.webp'

const copy = {
  heading: `Customer Data Platform`,
  buttonText: `Get a demo`,
}

const Hero: FC<PropsWithChildren<unknown>> = () => {
  return (
    <Wrapper>
      <HeroComponent variant="centered">
        <HeroCopy>
          <CopyTextBase centered={true}>
            <CopyText.Heading variant="h1" tag="h1">
              {copy.heading}
            </CopyText.Heading>
            <StyledCopyTextDescription variant="pExtraLarge" tag="h2">
              Accelerate growth with a customer data platform (CDP) and make good data available to all teams.
            </StyledCopyTextDescription>
          </CopyTextBase>
          <HeroRecipe.Form>
            <DemoForm
              isProgressive={true}
              customFormIdentifier="site-cdp-demo"
              successMessageAppearance="twilioLight"
            />
          </HeroRecipe.Form>
        </HeroCopy>
        <HeroRecipe.Contents>
          <Picture>
            <source type="image/webp" srcSet={`${heroWebp}, ${heroWebp2x} 2x`} />
            <HeroImage
              hideOnMobile={true}
              width="1158"
              height="484"
              src={heroPng2x}
              alt="illustration: Segment diagram"
              loading="lazy"
            />
          </Picture>
        </HeroRecipe.Contents>
      </HeroComponent>
    </Wrapper>
  )
}

const Wrapper = styled.header`
  position: relative;
  z-index: 0;
  background-color: ${({ theme }) => theme.palette.twilio.green[10]};
`
const Picture = styled.picture`
  padding: 0 24px;

  ${HeroRecipe.Image} {
    margin: 0 auto;
  }
`

const HeroComponent = styled(HeroBase)`
  ${HeroRecipe.Wrapper} {
    padding-bottom: 0;
    ${({ theme }) => theme.breakpoints.up(`lg`)} {
      padding-left: 0;
      padding-right: 0;
    }
    ${({ theme }) => theme.breakpoints.down(`md`)} {
      row-gap: 20px;
      padding-bottom: 40px;
    }
  }
`

const StyledCopyTextDescription = styled(CopyText.Description)`
  max-width: 600px;
  padding: 10px;
  ${({ theme }) => theme.breakpoints.down(`md`)} {
    padding-top: 0;
  }
`

const HeroCopy = styled(HeroRecipe.Copy)`
  max-width: 844px;
  ${({ theme }) => theme.breakpoints.up(`lg`)} {
    margin-bottom: -75px;
    z-index: 1;
  }
`

const HeroImage = styled(HeroRecipe.Image)`
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
`

export default Hero
