import styled from '@emotion/styled'

import { useTheme } from '@emotion/react'

import FeatureBoxBase, * as FeatureBox from '@segment/matcha/components/FeatureBox'
import ColumnTrack from '@segment/matcha/components/ColumnTrack'

import SectionBase, * as Section from '@segment/matcha/recipes/Section'
import CopyTextBase, * as CopyText from '@segment/matcha/recipes/CopyText'

import useMatchMedia from '@segment/utils/useMatchMedia'

import getAbsoluteUrl from '@segment/utils/getAbsoluteUrl'

import { usePageConfig } from '@segment/contexts/page'

import CleanAndOrganizeDataImage from './CleanAndOrganizeDataImage'

const copy = {
  heading: `Clean and organize your customer data`,
  description: `Customer data management is the process of acquiring, organizing, and using customer data. Customer data platforms make this process simple by automating and organizing data collection so that data is always usable.`,
}

type ColData = {
  heading: string
  description: string
  url: string
}

const columnTrackData: ColData[] = [
  {
    heading: `6 principles to manage data`,
    description: `Want accurate and useful data? Read our guide to learn why you need a data governance strategy and tracking plan, and how to break data silos.`,
    url: `/resources/cdp/customer-data-management/`,
  },
  {
    heading: `Scalable data governance`,
    description: `It’s really easy to collect useless data or collect the right data in the wrong ways. Make sure your data governance strategy can grow as you do.`,
    url: `/academy/collecting-data/scalable-data-governance/`,
  },
  {
    heading: `What is a data plan?`,
    description: `A data tracking plan can make or break your governance strategy. Learn how data tracking can go beyond spreadsheets to serving as a project management tool.`,
    url: `https://segment.com/blog/what-is-a-tracking-plan/`,
  },
]

const CleanAndOrganizeData = () => {
  // Mobile breakpoints
  const theme = useTheme()
  const mobileResolutionMediaQuery = theme.breakpoints.down(`md`, false)
  const isSmallResolution = useMatchMedia(mobileResolutionMediaQuery, true)

  const pageConfig = usePageConfig()

  return (
    <>
      <SectionComponent variant="2Col">
        <SectionCopy alignSelf="center">
          <CopyTextBase>
            <CopyText.Heading tag="h3">{copy.heading}</CopyText.Heading>
            <CopyText.Description tag="h2">{copy.description}</CopyText.Description>
          </CopyTextBase>
        </SectionCopy>
        <Section.Contents>
          <CleanAndOrganizeDataImage />
        </Section.Contents>
      </SectionComponent>
      <StyledColumnTrack columns={1} overrides={{ lg: 3 }} center={false}>
        {columnTrackData.map(({ heading, description, url }) => (
          <StyledFeatureBoxBase alignment="left" key={heading} headerPosition="top">
            <FeatureBox.Body>
              <FeatureBox.Title>{heading}</FeatureBox.Title>
              <FeatureBox.Text>{description}</FeatureBox.Text>
              <StyledCopyTextLink href={getAbsoluteUrl(url, pageConfig)} size={isSmallResolution ? `small` : `medium`}>
                Learn more
              </StyledCopyTextLink>
            </FeatureBox.Body>
          </StyledFeatureBoxBase>
        ))}
      </StyledColumnTrack>
    </>
  )
}

const SectionComponent = styled(SectionBase)`
  overflow-x: visible;
  justify-content: center;
  ${Section.Wrapper} {
    padding: 0;
    column-gap: 0;
    ${({ theme }) => theme.breakpoints.down(`md`)} {
      padding-bottom: 0;
      grid-template-areas:
        'copy'
        'contents';
    }
  }
`

const SectionCopy = styled(Section.Copy)`
  margin: 0 auto;
  ${({ theme }) => theme.breakpoints.down(`md`)} {
    margin-left: 0;
  }
  ${({ theme }) => theme.breakpoints.up(`lg`)} {
    max-width: 392px;
  }
`

const StyledFeatureBoxBase = styled(FeatureBoxBase)`
  ${({ theme }) => theme.breakpoints.down(`md`)} {
    row-gap: 0;
  }
`

const StyledColumnTrack = styled(ColumnTrack)`
  align-items: flex-start;
  ${({ theme }) => theme.breakpoints.down(`md`)} {
    padding-top: 24px;
    row-gap: 32px;
  }
  ${({ theme }) => theme.breakpoints.up(`md`)} {
    padding-left: 80px;
    padding-right: 80px;
  }
`

const StyledCopyTextLink = styled(CopyText.Link)`
  ${({ theme }) => theme.breakpoints.down(`md`)} {
    margin-top: 8px;
    margin-bottom: 0;
  }
`

export default CleanAndOrganizeData
