import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["appearance", "className", "before", "after", "isProgressive", "isLeadEnrichmentFormEnabled", "overrideConsent", "customFormIdentifier", "customTrackingType", "successMessageAppearance", "onEmailCompleted", "buttonText", "customDisclaimer", "driftCampaignId", "successMessage"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo, useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useAppearance, AppearanceProvider } from '@segment/matcha/foundations/AppearanceContext';
import Button from '@segment/matcha/components/Button';
import { CompanyEmailField, companyEmailMessages, FullNameField, fullNameMessages, PhoneNumberField, getPhoneErrorMessage, CompanyNameField, companyNameMessages, CustomFormIdentifierField, phoneNumberMessages, TWILIO_PHONE_VALID_FIELD, TWILIO_PHONE_ERRORS_FIELD, TWILIO_PHONE_CALLING_COUNTRY_CODE, TWILIO_PHONE_COUNTRY_CODE, TWILIO_PHONE_NUMBER, TWILIO_PHONE_NATIONAL_FORMAT } from '@segment/templates/FormFields';
import Disclaimer from '@segment/templates/Disclaimer';
import { FormSubmitMessage } from '@segment/templates/FormSubmitMessage/FormSubmitMessage';
import FormSubmitAction from '@segment/templates/FormSubmitAction';
import TrackableForm, { errorMessage } from '@segment/templates/TrackableForm';
import { createDemoLead, trackValidationErrorDemoForm } from '@segment/tracking/scripts/helpers';
import useLocation from '@segment/api/utils/useLocation';
import useMatchMedia from '@segment/utils/useMatchMedia';
import { scrollToAnchor } from '@segment/utils/useScrollToAnchor';
import sharedEmailRegex from '@segment/utils/regexes/sharedEmail';
import emailRegex from '@segment/utils/regexes/email';
import formRegex from '@segment/utils/regexes/form';
import lastNameRegex from '@segment/utils/regexes/lastName';
import forbiddenEmailRegex from '@segment/utils/regexes/forbiddenEmail';
import { usePageConfig } from '@segment/contexts/page';
import { ProgressiveProfileForm } from '../ProgressiveProfileForm/ProgressiveProfileForm';
import * as views from './DemoForm.views';
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var MOBILE_OFFSET = -150;
var DESKTOP_OFFSET = -350;
var FORM_ID = "demo-form"; // Set up demo form props since we define some of these props here in the template

export var DemoForm = function DemoForm(_ref) {
  var appearance = _ref.appearance,
      className = _ref.className,
      before = _ref.before,
      after = _ref.after,
      _ref$isProgressive = _ref.isProgressive,
      isProgressive = _ref$isProgressive === void 0 ? false : _ref$isProgressive,
      _ref$isLeadEnrichment = _ref.isLeadEnrichmentFormEnabled,
      isLeadEnrichmentFormEnabled = _ref$isLeadEnrichment === void 0 ? true : _ref$isLeadEnrichment,
      _ref$overrideConsent = _ref.overrideConsent,
      overrideConsent = _ref$overrideConsent === void 0 ? true : _ref$overrideConsent,
      customFormIdentifier = _ref.customFormIdentifier,
      customTrackingType = _ref.customTrackingType,
      successMessageAppearance = _ref.successMessageAppearance,
      onEmailCompleted = _ref.onEmailCompleted,
      _ref$buttonText = _ref.buttonText,
      buttonText = _ref$buttonText === void 0 ? "Get a demo" : _ref$buttonText,
      customDisclaimer = _ref.customDisclaimer,
      driftCampaignId = _ref.driftCampaignId,
      customSuccessMessage = _ref.successMessage,
      rest = _objectWithoutProperties(_ref, _excluded);

  var _usePageConfig = usePageConfig(),
      baseUrl = _usePageConfig.baseUrl;

  var theme = useTheme();
  var formAppearance = useAppearance(appearance);
  var countryCode = useLocation(); // Initialize form here since we need the form initialized to handle progressive fields and the personal email functionality

  var _useState = useState(false),
      emailCompleted = _useState[0],
      setEmailCompleted = _useState[1];

  var _useState2 = useState(undefined),
      intPhoneErrorCode = _useState2[0],
      setIntPhoneErrorCode = _useState2[1];

  var _useState3 = useState(),
      submittedEmail = _useState3[0],
      setSubmittedEmail = _useState3[1];
  /* eslint-disable camelcase */


  var schema = useMemo(function () {
    var _z$object;

    return z.object((_z$object = {
      email: z.string({
        required_error: companyEmailMessages.required
      }).regex(formRegex, {
        message: companyEmailMessages.invalid
      }).regex(emailRegex, {
        message: companyEmailMessages.invalid
      })
      /* This is equal to .email( { message: companyEmailMessages.invalid }) but with a custom regex */
      .regex(sharedEmailRegex, {
        message: companyEmailMessages.invalid
      }).regex(forbiddenEmailRegex, {
        message: companyEmailMessages.forbiddenEmail
      }),
      name: z.string({
        required_error: fullNameMessages.required
      }).regex(formRegex, {
        message: fullNameMessages.invalid
      }).regex(lastNameRegex, {
        message: fullNameMessages.invalid
      }),
      phone: z.string({
        invalid_type_error: phoneNumberMessages.invalid
      }).min(1, phoneNumberMessages.required).refine(function () {
        return intPhoneErrorCode === undefined;
      }, {
        message: getPhoneErrorMessage(intPhoneErrorCode)
      })
    }, _defineProperty(_z$object, TWILIO_PHONE_VALID_FIELD, z.string().optional()), _defineProperty(_z$object, TWILIO_PHONE_ERRORS_FIELD, z.string().optional()), _defineProperty(_z$object, TWILIO_PHONE_CALLING_COUNTRY_CODE, z.string().optional()), _defineProperty(_z$object, TWILIO_PHONE_COUNTRY_CODE, z.string().optional()), _defineProperty(_z$object, TWILIO_PHONE_NUMBER, z.string().optional()), _defineProperty(_z$object, TWILIO_PHONE_NATIONAL_FORMAT, z.string().optional()), _defineProperty(_z$object, "company", z.string({
      required_error: companyNameMessages.required
    }).regex(formRegex, {
      message: companyNameMessages.invalid
    })), _z$object));
  }, [intPhoneErrorCode]);
  /* eslint-enable camelcase */

  var methods = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
    shouldUnregister: true,
    resolver: zodResolver(schema)
  }); // Watch for personal email

  var watchedEmail = methods === null || methods === void 0 ? void 0 : methods.watch("email", ""); // If this is the progressive version of the form, set up email watching
  // Otherwise just always return true

  var showProgressiveFields = isProgressive ? Boolean(watchedEmail || emailCompleted) : true; // Mobile breakpoints

  var mobileResolutionMediaQuery = theme.breakpoints.down("md", false);
  var isSmallResolution = useMatchMedia(mobileResolutionMediaQuery, true);
  useEffect(function () {
    if (watchedEmail) {
      setEmailCompleted(true);
      if (onEmailCompleted) onEmailCompleted();
    }
  }, [watchedEmail]);
  var successMessage = customSuccessMessage || {
    heading: "Thank you, you\u2019re all set!",
    description: "We'll get back to you shortly. For now, you can create your workspace by clicking below.",
    buttonUrl: "".concat(baseUrl, "/signup/?utm_campaign=demo"),
    buttonText: "Create your free workspace"
  };

  var collectDriftFormData = function collectDriftFormData(formData) {
    var unTypedWindow = window;

    if (unTypedWindow.drift) {
      unTypedWindow.drift.api.collectFormData(_objectSpread(_objectSpread({}, formData), {}, {
        firstName: formData.name.split(" ")[0],
        lastName: formData.name.split(" ").slice(1).join(" ")
      }), {
        campaignId: driftCampaignId
      });
    }
  };

  useEffect(function () {
    if (methods.getValues("phone")) {
      methods.trigger("phone");
    }
  }, [intPhoneErrorCode]);
  return _jsxs(AppearanceProvider, {
    appearance: formAppearance,
    children: [_jsx(views.FormWrapper, {
      id: FORM_ID,
      children: _jsxs(TrackableForm, _objectSpread(_objectSpread({
        gap: "small",
        methods: methods,
        className: className,
        before: before,
        after: after,
        success: isLeadEnrichmentFormEnabled ? _jsx(_Fragment, {}) : _jsx(FormSubmitAction, _objectSpread({
          form: "demo",
          status: "success",
          appearance: successMessageAppearance,
          "data-hook": "demo-signup-success-message"
        }, successMessage)),
        error: _jsx(FormSubmitAction, _objectSpread({
          form: "demo",
          status: "error",
          "data-hook": "demo-form-error-message"
        }, errorMessage)),
        onSubmit: function onSubmit(data) {
          setSubmittedEmail(data.email);
          createDemoLead(data);

          if (driftCampaignId) {
            collectDriftFormData(data);
          }

          if (isSmallResolution && showProgressiveFields) {
            scrollToAnchor(FORM_ID, MOBILE_OFFSET, false);
          } else {
            scrollToAnchor(FORM_ID, DESKTOP_OFFSET, false);
          }
        },
        onFailure: trackValidationErrorDemoForm,
        overrideConsent: overrideConsent,
        customFormIdentifier: customFormIdentifier,
        customTrackingType: customTrackingType
      }, rest), {}, {
        children: [_jsx(CompanyEmailField, {
          onBlur: trackValidationErrorDemoForm
        }), showProgressiveFields && _jsxs(_Fragment, {
          children: [_jsx(FullNameField, {
            onBlur: trackValidationErrorDemoForm
          }), _jsx(PhoneNumberField, {
            countryCode: countryCode,
            onValidate: setIntPhoneErrorCode,
            onBlur: trackValidationErrorDemoForm
          }), _jsx(CompanyNameField, {
            onBlur: trackValidationErrorDemoForm
          })]
        }), customFormIdentifier && _jsx(CustomFormIdentifierField, {
          value: customFormIdentifier
        }), _jsx(views.ButtonWrapper, {
          children: _jsx(Button, {
            color: "primary",
            variant: "filled",
            size: "large",
            expanded: true,
            children: buttonText
          })
        }), showProgressiveFields && _jsx(views.DisclaimerWrapper, {
          children: _jsx(Disclaimer, {
            data: customDisclaimer,
            appearance: successMessageAppearance
          })
        })]
      }))
    }), isLeadEnrichmentFormEnabled && methods.formState.isSubmitSuccessful && _jsx(ProgressiveProfileForm, {
      before: _jsx(FormSubmitMessage, {
        status: "success",
        form: "demo"
      }),
      email: submittedEmail,
      successMessage: successMessage
    })]
  });
};
export default DemoForm;