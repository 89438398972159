
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/customer-data-platform",
      function () {
        return require("private-next-pages/customer-data-platform.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/customer-data-platform"])
      });
    }
  