import styled from '@emotion/styled'

import Image from '@segment/matcha/components/Image'

import HowCDPWorksPng2x from './assets/HowCDPWorks@2x.png'
import HowCDPWorksWebp from './assets/HowCDPWorks.webp'
import HowCDPWorksWebp2x from './assets/HowCDPWorks@2x.webp'

const HowCDPWorksImage = () => {
  return (
    <picture>
      <source type="image/webp" srcSet={`${HowCDPWorksWebp}, ${HowCDPWorksWebp2x} 2x`} />
      <StyledImage width="1160" height="540" src={HowCDPWorksPng2x} alt="Illustration: How CDP Works" loading="lazy" />
    </picture>
  )
}

const StyledImage = styled(Image)`
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
`

export default HowCDPWorksImage
