import styled from '@emotion/styled'

import SectionBase, * as Section from '@segment/matcha/recipes/Section'
import CopyTextBase, * as CopyText from '@segment/matcha/recipes/CopyText'

import CreateMeaningfulInteractionsImage from './CreateMeaningfulInteractionsImage'

const copy = {
  heading: `Create meaningful interactions from data insights`,
  description: `Every customer interaction should feel more personal than the last—ads are relevant, web pages are tailored, and product experiences feel specially crafted. Customer data platforms make it easy for businesses to ensure this level of winning customer engagement.`,
}

export const CreateMeaningfulInteractions = () => {
  return (
    <SectionComponent variant="2Col" copyVariant="narrow" reverse={true}>
      <Section.Copy alignSelf="center">
        <CopyTextBase>
          <CopyText.Heading tag="h2">{copy.heading}</CopyText.Heading>
          <CopyText.Description tag="h2">{copy.description}</CopyText.Description>
        </CopyTextBase>
      </Section.Copy>
      <StyledSectionContents>
        <CreateMeaningfulInteractionsImage />
      </StyledSectionContents>
    </SectionComponent>
  )
}

const SectionComponent = styled(SectionBase)`
  overflow-x: visible;
  justify-content: center;
  ${Section.Wrapper} {
    padding-top: 80px;
    padding-bottom: 80px;
    max-width: 1280px;
    ${({ theme }) => theme.breakpoints.down(`md`)} {
      padding-top: 0;
      grid-template-areas:
        'contents'
        'copy';
    }
    ${({ theme }) => theme.breakpoints.up(`md`)} {
      padding-left: 40px;
      padding-right: 40px;
    }
  }
`

const StyledSectionContents = styled(Section.Contents)`
  width: 90%;
  padding-right: 40px;
  ${({ theme }) => theme.breakpoints.down(`md`)} {
    width: 100%;
    padding-right: 0;
  }
`

export default CreateMeaningfulInteractions
