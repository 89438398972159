import styled from '@emotion/styled'
import { useTheme } from '@emotion/react'

import Image from '@segment/matcha/components/Image'

import BuildInsigtfulCustomerAnalyticsPng2x from './assets/BuildInsigtfulCustomerAnalytics@2x.png'
import BuildInsigtfulCustomerAnalyticsWebp from './assets/BuildInsigtfulCustomerAnalytics.webp'
import BuildInsigtfulCustomerAnalyticsWebp2x from './assets/BuildInsigtfulCustomerAnalytics@2x.webp'

const BuildInsigtfulCustomerAnalyticsImage = () => {
  const theme = useTheme()
  return (
    <picture>
      <source
        type="image/webp"
        media={theme.breakpoints.up(`lg`, false)}
        srcSet={`${BuildInsigtfulCustomerAnalyticsWebp}, ${BuildInsigtfulCustomerAnalyticsWebp2x} 2x`}
      />
      <StyledImage
        width="710"
        height="540"
        src={BuildInsigtfulCustomerAnalyticsPng2x}
        alt="Illustration: Build Insigtful Customer Analytics"
        loading="lazy"
      />
    </picture>
  )
}

const StyledImage = styled(Image)`
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  ${({ theme }) => theme.breakpoints.up(`xl`)} {
    max-width: 575px;
  }
`

export default BuildInsigtfulCustomerAnalyticsImage
