import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import React from 'react';
import Button from '@segment/matcha/components/Button';
import { ChevronRightIcon } from '@segment/matcha/components/Icons/Common';
import CopyTextBase, * as CopyText from '@segment/matcha/recipes/CopyText';
import SignupButton from '@segment/matcha/recipes/SignupButton';
import LogoTiles from '@segment/templates/LogoTiles';
import { withDesignTokens } from '@segment/matcha/useDesignTokens';
import getAbsoluteUrl from '@segment/utils/getAbsoluteUrl';
import { usePageConfig } from '@segment/contexts/page';
import * as views from './GettingStarted.views';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";

var BaseGettingStarted = function BaseGettingStarted(_ref) {
  var _ref$heading = _ref.heading,
      heading = _ref$heading === void 0 ? "Getting started is easy" : _ref$heading,
      _ref$description = _ref.description,
      description = _ref$description === void 0 ? "Start connecting your data with Segment." : _ref$description,
      _ref$demoUtms = _ref.demoUtms,
      demoUtms = _ref$demoUtms === void 0 ? "" : _ref$demoUtms,
      tokens = _ref.tokens;
  var pageConfig = usePageConfig();
  return _jsxs(views.Wrapper, _objectSpread(_objectSpread({}, tokens), {}, {
    children: [_jsxs(views.Copy, {
      children: [_jsxs(CopyTextBase, {
        centered: true,
        children: [_jsx(CopyText.Heading, {
          tag: "h3",
          variant: "h2",
          children: heading
        }), _jsx(CopyText.Description, {
          tag: "p",
          variant: "pLarge",
          children: description
        })]
      }), _jsxs(views.Content, {
        children: [_jsx(Button, {
          size: "large",
          color: "primary",
          variant: "filled",
          tag: "a",
          href: getAbsoluteUrl("/demo/".concat(demoUtms), pageConfig),
          children: "Get a demo"
        }), _jsx(SignupButton, {
          tag: "a",
          variant: "outlined",
          iconEnd: ChevronRightIcon,
          children: "Create a free account"
        })]
      })]
    }), _jsx(LogoTiles, {})]
  }));
};

export var GettingStarted = withDesignTokens(BaseGettingStarted, function () {
  return {};
}, {
  light: function light(theme) {
    return {
      backgroundColor: theme.palette.neutrals.cloud[100]
    };
  },
  dark: function dark(theme) {
    return {
      backgroundColor: theme.palette.brand.darkAcai[700]
    };
  },
  twilioLight: function twilioLight(theme) {
    return {
      backgroundColor: theme.palette.twilio.gray[10]
    };
  }
});