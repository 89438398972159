import { useTheme } from '@emotion/react'

import Image from '@segment/matcha/components/Image'

import SegmentOnCustomerDataPng2x from './assets/SegmentOnCustomerData@2x.png'
import SegmentOnCustomerDataWebp from './assets/SegmentOnCustomerData.webp'
import SegmentOnCustomerDataWebp2x from './assets/SegmentOnCustomerData@2x.webp'

const SegmentOnCustomerDataImage = () => {
  const theme = useTheme()
  return (
    <picture>
      <source
        type="image/webp"
        media={theme.breakpoints.up(`lg`, false)}
        srcSet={`${SegmentOnCustomerDataWebp}, ${SegmentOnCustomerDataWebp2x} 2x`}
      />
      <Image
        width="670"
        height="440"
        src={SegmentOnCustomerDataPng2x}
        alt="Illustration: Two columns of four placeholder rows"
        loading="lazy"
      />
    </picture>
  )
}

export default SegmentOnCustomerDataImage
