import styled from '@emotion/styled'
import { useTheme } from '@emotion/react'

import Image from '@segment/matcha/components/Image'

import CreateMeaningfulInteractionsPng2x from './assets/CreateMeaningfulInteractions@2x.png'
import CreateMeaningfulInteractionsWebp from './assets/CreateMeaningfulInteractions.webp'
import CreateMeaningfulInteractionsWebp2x from './assets/CreateMeaningfulInteractions@2x.webp'

const CreateMeaningfulInteractionsImage = () => {
  const theme = useTheme()
  return (
    <picture>
      <source
        type="image/webp"
        media={theme.breakpoints.up(`lg`, false)}
        srcSet={`${CreateMeaningfulInteractionsWebp}, ${CreateMeaningfulInteractionsWebp2x} 2x`}
      />
      <StyledImage
        width="591"
        height="535"
        src={CreateMeaningfulInteractionsPng2x}
        alt="Illustration: Create meaningful interactions from data insights"
        loading="lazy"
      />
    </picture>
  )
}

const StyledImage = styled(Image)`
  display: block;
  height: auto;
  max-width: 100%;
  margin: 0 auto;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
`

export default CreateMeaningfulInteractionsImage
