import styled from '@emotion/styled'

import SectionBase, * as Section from '@segment/matcha/recipes/Section'
import CopyTextBase, * as CopyText from '@segment/matcha/recipes/CopyText'

import GoodUserExperiencesImage from './GoodUserExperiencesImage'

const copy = {
  heading: `Great user experiences require better data`,
  description: `Shoppers interact with you across your website, ads, emails, and other channels before making a purchase. Customer data platforms help unify disparate data to give you a single view of the customer journey needed to deliver personalization experiences.`,
}

export const GoodUserExperiences = () => {
  return (
    <SectionComponent variant="2Col" copyVariant="narrow">
      <StyledSectionCopy alignSelf="center">
        <CopyTextBase>
          <CopyText.Heading tag="h2">{copy.heading}</CopyText.Heading>
          <CopyText.Description tag="h2">{copy.description}</CopyText.Description>
        </CopyTextBase>
      </StyledSectionCopy>
      <Section.Contents>
        <GoodUserExperiencesImage />
      </Section.Contents>
    </SectionComponent>
  )
}

const SectionComponent = styled(SectionBase)`
  overflow-x: visible;
  justify-content: center;

  ${Section.Wrapper} {
    padding-bottom: 80px;
    max-width: 1280px;
    ${({ theme }) => theme.breakpoints.down(`md`)} {
      padding-top: 60px;
      grid-template-areas:
        'contents'
        'copy';
    }
    ${({ theme }) => theme.breakpoints.up(`md`)} {
      padding-left: 40px;
      padding-right: 40px;
    }
  }
`

const StyledSectionCopy = styled(Section.Copy)`
  ${({ theme }) => theme.breakpoints.up(`lg`)} {
    padding-left: 80px;
    padding-right: 0px;
  }
`

export default GoodUserExperiences
