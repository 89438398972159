import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'

import ColumnTrack from '@segment/matcha/components/ColumnTrack'
import FeatureBoxBase, * as FeatureBox from '@segment/matcha/components/FeatureBox'

import Media from '@segment/matcha/components/Media'

import CopyTextBase, * as CopyText from '@segment/matcha/recipes/CopyText'
import SectionBase, * as Section from '@segment/matcha/recipes/Section'

import useMatchMedia from '@segment/utils/useMatchMedia'

import HowCDPWorksImage from './HowCDPWorksImage'

const featureData = [
  {
    heading: `Collect`,
    body: `A CDP helps you collect, clean, and structure your data.`,
  },
  {
    heading: `Govern`,
    body: `With all of your data in one place, you can remove bad data and respect user privacy.`,
  },
  {
    heading: `Synthesize`,
    body: `Use good data to build profiles and audiences to use across every tool.`,
  },
  {
    heading: `Activate`,
    body: `Use your data, profiles, and audiences everywhere your business needs.`,
  },
]

const copy = {
  heading: `How a customer data platform works`,
  description: `A customer data platform helps you capture data from every customer interaction and combine that with the systems data in your warehouse, build user profiles and audiences, and connect the right data to the tools your teams use to grow revenue.`,
  imageDescription: `Collect data from touch points like Facebook, your company’s website, email, and any other place a customer might interact with your company. Then unify the data into complete user profiles and make it possible to use the profiles across Facebook, Google, and your internal company dashboards. A single platform helps you create personalized experiences and get the insights you need to better understand your customers.`,
}

export const HowCDPWorks = () => {
  // Mobile breakpoints
  const theme = useTheme()
  const mobileResolutionMediaQuery = theme.breakpoints.down(`md`, false)
  const isSmallResolution = useMatchMedia(mobileResolutionMediaQuery, true)
  return (
    <>
      <StyledSectionBase variant="centered">
        <StyledSectionCopy>
          <CopyTextBase centered={!isSmallResolution}>
            <CopyText.Heading tag="h2">{copy.heading}</CopyText.Heading>
            <CopyText.Description tag="p" variant="pLarge">
              {copy.description}
            </CopyText.Description>
          </CopyTextBase>
        </StyledSectionCopy>
        <Section.Contents>
          <ColumnTrack columns={1} overrides={{ md: 4 }}>
            {featureData.map((box) => (
              <StyledFeatureBoxBase key={box.heading} headerPosition="top">
                <FeatureBox.Body>
                  <FeatureBox.Title tag="h3" variant="h5">
                    {box.heading}
                  </FeatureBox.Title>
                  <FeatureBox.Text>{box.body}</FeatureBox.Text>
                </FeatureBox.Body>
              </StyledFeatureBoxBase>
            ))}
          </ColumnTrack>
        </Section.Contents>
      </StyledSectionBase>
      <StyledImageSectionBase variant="centered">
        <Media greaterThanOrEqual="md">
          <HowCDPWorksImage />
        </Media>
        <StyledImageCopyTextBase centered={!isSmallResolution}>
          <CopyText.Description tag="p" variant="pLarge">
            {copy.imageDescription}
          </CopyText.Description>
        </StyledImageCopyTextBase>
      </StyledImageSectionBase>
    </>
  )
}

const StyledSectionBase = styled(SectionBase)`
  max-width: 1280px;

  ${Section.Wrapper} {
    ${({ theme }) => theme.breakpoints.down(`md`)} {
      padding-top: 0;
      row-gap: 40px;
    }
  }
`

const StyledImageSectionBase = styled(SectionBase)`
  max-width: 1280px;
  ${Section.Wrapper} {
    padding-top: 40px;
    ${({ theme }) => theme.breakpoints.down(`md`)} {
      row-gap: 0;
      padding-bottom: 64px;
    }
  }
  ${({ theme }) => theme.breakpoints.down(`md`)} {
    padding-bottom: 0;
  }
`

const StyledSectionCopy = styled(Section.Copy)`
  max-width: 844px;
`

const StyledImageCopyTextBase = styled(CopyTextBase)`
  padding-top: 0;
  max-width: 844px;
`

const StyledFeatureBoxBase = styled(FeatureBoxBase)`
  max-width: 336px;
  justify-self: center;
  text-align: left;
  row-gap: 0;

  ${({ theme }) => theme.breakpoints.up(`xs`)} {
    max-width: unset;
    justify-content: flex-start;
    row-gap: 0;
  }

  ${({ theme }) => theme.breakpoints.up(`lg`)} {
    padding: 0 15px;
    row-gap: 0;
  }

  ${({ theme }) => theme.breakpoints.up(`xxl`)} {
    padding: 0px 15px;
    row-gap: 0;
  }
`

export default HowCDPWorks
