import styled from '@emotion/styled'

import SectionBase, * as Section from '@segment/matcha/recipes/Section'
import CopyTextBase, * as CopyText from '@segment/matcha/recipes/CopyText'

import getAbsoluteUrl from '@segment/utils/getAbsoluteUrl'

import { usePageConfig } from '@segment/contexts/page'

import SpottingBadDataImage from './SpottingBadDataImage'

const copy = {
  heading: `Extensibility on an open platform`,
  description: `Our flexible, vendor-agnostic platform supports any use case, offering quick easy integration with your stack through 450+ pre-built connections and developer-friendly interfaces, ensuring compatibility with your existing tools and warehouse.`,
}

const link = {
  text: `Learn more`,
  url: `/catalog/`,
}

const SpottingBadData = () => {
  const pageConfig = usePageConfig()
  return (
    <>
      <SectionComponent variant="2Col" reverse={true}>
        <SectionCopy alignSelf="center">
          <CopyTextBase>
            <CopyText.Heading tag="h3">{copy.heading}</CopyText.Heading>
            <CopyText.Description tag="h3">{copy.description}</CopyText.Description>
            <CopyText.Link href={getAbsoluteUrl(link.url, pageConfig)}>{link.text}</CopyText.Link>
          </CopyTextBase>
        </SectionCopy>
        <Section.Contents>
          <SpottingBadDataImage />
        </Section.Contents>
      </SectionComponent>
    </>
  )
}

const SectionComponent = styled(SectionBase)`
  overflow-x: visible;
  justify-content: center;
  ${Section.Wrapper} {
    padding-top: 0;
    padding-bottom: 80px;
    padding-left: 120px;
    column-gap: 0;
    padding-right: 0;
    ${({ theme }) => theme.breakpoints.down(`md`)} {
      padding-bottom: 30px;
      padding-right: 0;
      padding-left: 0;
      grid-template-areas:
        'contents'
        'copy';
    }
  }
`

const SectionCopy = styled(Section.Copy)`
  margin: 0 auto;
  max-width: 392px;
  ${({ theme }) => theme.breakpoints.down(`md`)} {
    margin-left: 0;
  }
`

export default SpottingBadData
