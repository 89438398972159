import styled from '@emotion/styled'
import { useTheme } from '@emotion/react'

import Image from '@segment/matcha/components/Image'

import ImproveDataProtectionPng2x from './assets/ImproveDataProtection@2x.png'
import ImproveDataProtectionWebp from './assets/ImproveDataProtection.webp'
import ImproveDataProtectionWebp2x from './assets/ImproveDataProtection@2x.webp'

const ImproveDataProtectionImage = () => {
  const theme = useTheme()
  return (
    <picture>
      <source
        type="image/webp"
        media={theme.breakpoints.up(`lg`, false)}
        srcSet={`${ImproveDataProtectionWebp}, ${ImproveDataProtectionWebp2x} 2x`}
      />
      <StyledImage
        width="587"
        height="487"
        src={ImproveDataProtectionPng2x}
        alt="Illustration: Authentication and authorization"
        loading="lazy"
      />
    </picture>
  )
}

const StyledImage = styled(Image)`
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
`

export default ImproveDataProtectionImage
