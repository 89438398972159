import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["appearance"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import Link from 'next/link';
import { AppearanceProvider, useAppearance } from '@segment/matcha/foundations/AppearanceContext';
import Button from '@segment/matcha/components/Button';
import SegmentLogo from '@segment/matcha/components/SegmentLogo';
import FooterRecipe from '@segment/matcha/recipes/Footer';
import getAbsoluteUrl from '@segment/utils/getAbsoluteUrl';
import { usePageConfig } from '@segment/contexts/page';
import getData from './data';
import * as views from './Footer.views';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";

var Footer = function Footer(_ref) {
  var appearance = _ref.appearance,
      props = _objectWithoutProperties(_ref, _excluded);

  var pageConfig = usePageConfig();
  var footerAppearance = useAppearance(appearance);
  var ctaText = "See how it works";
  var data = getData(pageConfig);
  return _jsx(FooterRecipe, _objectSpread({
    bottomLinks: data.bottomLinks,
    mainLinks: data.mainLinks,
    copyright: data.copyright,
    logo: _jsx(AppearanceProvider, {
      appearance: footerAppearance,
      children: _jsx(Link, {
        href: getAbsoluteUrl("/", pageConfig),
        passHref: true,
        children: _jsx(views.BrandLink, {
          children: _jsx(SegmentLogo, {
            alt: "Twilio, Inc.",
            footer: true
          })
        })
      })
    }),
    cta: _jsx(Button, {
      color: "primary",
      variant: "filled",
      size: "large",
      href: getAbsoluteUrl("/demo/", pageConfig),
      children: ctaText
    }),
    appearance: appearance
  }, props));
};

export default Footer;