import styled from '@emotion/styled'
import { useTheme } from '@emotion/react'

import Image from '@segment/matcha/components/Image'

import EverythingYouNeedToKnowPng2x from './assets/EverythingYouNeedToKnow@2x.png'
import EverythingYouNeedToKnowWebp from './assets/EverythingYouNeedToKnow.webp'
import EverythingYouNeedToKnowWebp2x from './assets/EverythingYouNeedToKnow@2x.webp'

const EverythingYouNeedToKnowImage = () => {
  const theme = useTheme()
  return (
    <picture>
      <source
        type="image/webp"
        media={theme.breakpoints.up(`lg`, false)}
        srcSet={`${EverythingYouNeedToKnowWebp}, ${EverythingYouNeedToKnowWebp2x} 2x`}
      />
      <StyledImage
        width="670"
        height="442"
        src={EverythingYouNeedToKnowPng2x}
        alt="Illustration: One prong to three prongs"
        loading="lazy"
      />
    </picture>
  )
}

const StyledImage = styled(Image)`
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
`

export default EverythingYouNeedToKnowImage
