import styled from '@emotion/styled'

import { useTheme } from '@emotion/react'

import ColumnTrack from '@segment/matcha/components/ColumnTrack'

import { ChevronRightIcon } from '@segment/matcha/components/Icons/Common'

import CardBase, * as Card from '@segment/matcha/recipes/Card'

import CopyTextBase, * as CopyText from '@segment/matcha/recipes/CopyText'
import SectionBase, * as Section from '@segment/matcha/recipes/Section'

import useMatchMedia from '@segment/utils/useMatchMedia'

import getAbsoluteUrl from '@segment/utils/getAbsoluteUrl/getAbsoluteUrl'

import { usePageConfig } from '@segment/contexts/page'

const cards = [
  {
    title: `Choosing the best CDP in 6 steps`,
    body: `If you follow the six steps outlined in our guide, you'll have a much better handle on finding the right customer data platform that fits your company's unique needs.`,
    link: `/resources/cdp/choose-best-customer-data-platform/`,
  },
  {
    title: `Navigating the sea of customer tools`,
    body: `There are 7,000+ tools that use customer data in one form or another. You need to know which tools require customer data for today and for tomorrow.`,
    link: `/academy/choosing-stack/how-to-navigate-the-sea-of-customer-data-tools/`,
  },
  {
    title: `Buyer’s Guide to CDPs`,
    body: `Our buyer’s guide for CDPs will help you understand when it’s time to buy a CDP, which features you need, and how to evaluate the various CDP vendors out there.`,
    link: `https://learn.segment.com/cdp-buying-guide/`,
  },
]

const copy = {
  heading: `How to choose a CDP`,
  description: `Choosing the right customer data platform for your organization is a big decision. You need to know the use cases you want to solve, your specific requirements, and whether the vendor is experienced working with companies like yours. Here are a few resources to help you choose the right customer data platform:`,
}

export const HowToChooseACDP = () => {
  // Mobile breakpoints
  const theme = useTheme()
  const mobileResolutionMediaQuery = theme.breakpoints.down(`md`, false)
  const isSmallResolution = useMatchMedia(mobileResolutionMediaQuery, true)

  const pageConfig = usePageConfig()

  return (
    <Wrapper>
      <StyledSectionBase variant="centered">
        <StyledSectionCopy>
          <CopyTextBase centered={!isSmallResolution}>
            <CopyText.Heading>{copy.heading}</CopyText.Heading>
            <CopyText.Description tag="p" variant="pMedium">
              {copy.description}
            </CopyText.Description>
          </CopyTextBase>
        </StyledSectionCopy>
        <Section.Contents>
          <ColumnTrack columns={1} overrides={{ md: 3 }}>
            {cards.map((card, index) => (
              <CardBase key={index} href={getAbsoluteUrl(card.link, pageConfig)}>
                <Card.Body>
                  <Card.Copy>
                    <Card.Headline variant="h4" tag="h4">
                      {card.title}
                    </Card.Headline>
                    <Card.Description>{card.body}</Card.Description>
                  </Card.Copy>
                  <Card.Action alignment="right">
                    <ChevronRightIcon />
                  </Card.Action>
                </Card.Body>
              </CardBase>
            ))}
          </ColumnTrack>
        </Section.Contents>
      </StyledSectionBase>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  ${({ theme }) => theme.breakpoints.down(`md`)} {
    padding-top: 0;
  }
`

const StyledSectionBase = styled(SectionBase)`
  max-width: 1280px;
  ${Section.Wrapper} {
    ${({ theme }) => theme.breakpoints.down(`md`)} {
      padding-top: 64px;
      row-gap: 0;
    }
  }
`

const StyledSectionCopy = styled(Section.Copy)`
  max-width: 844px;
  ${({ theme }) => theme.breakpoints.down(`md`)} {
    padding-bottom: 40px;
  }
`

export default HowToChooseACDP
