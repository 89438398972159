import type { FC, PropsWithChildren } from 'react'

import styled from '@emotion/styled'

import SectionBase, * as Section from '@segment/matcha/recipes/Section'
import CopyTextBase, * as CopyText from '@segment/matcha/recipes/CopyText'

import EverythingYouNeedToKnow from './EverythingYouNeedToKnow'
import SpottingBadData from './SpottingBadData'
import SegmentOnCustomerData from './SegmentOnCustomerData'

const BenefitsOfACDP: FC<PropsWithChildren<unknown>> = () => {
  return (
    <StyledSectionBase variant="centered" copyVariant="narrow">
      <SectionCopy>
        <CopyTextBase centered={true}>
          <CopyText.Heading tag="h2">
            Why Twilio Segment: Learn about our complete, extensible, and scalable customer data platform
          </CopyText.Heading>
        </CopyTextBase>
      </SectionCopy>
      <ContentsStyled>
        <EverythingYouNeedToKnow />
        <SpottingBadData />
        <SegmentOnCustomerData />
      </ContentsStyled>
    </StyledSectionBase>
  )
}

const StyledSectionBase = styled(SectionBase)`
  ${Section.Wrapper} {
    padding-top: 0;
  }

  ${({ theme }) => theme.breakpoints.up(`lg`)} {
    padding-left: 40px;
    padding-right: 40px;

    ${Section.Wrapper} {
      padding-left: inherit;
      padding-right: inherit;
      justify-content: stretch;
    }
  }
  ${({ theme }) => theme.breakpoints.down(`md`)} {
    ${Section.Wrapper} {
      padding-bottom: 10px;
    }
  }
`

const SectionCopy = styled(Section.Copy)`
  margin: 0 auto;
  max-width: 844px;
`

const ContentsStyled = styled(Section.Contents)`
  display: grid;
  gap: 40px;
`

export default BenefitsOfACDP
