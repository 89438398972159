import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'

import CopyTextBase, * as CopyText from '@segment/matcha/recipes/CopyText'
import SectionBase, * as Section from '@segment/matcha/recipes/Section'

import TestimonialSlider from '@segment/templates/TestimonialSlider'

import useMatchMedia from '@segment/utils/useMatchMedia'

import { testimonialData } from './data'

const copy = {
  heading: `CDP success stories`,
  description: `Up to this point, we’ve mostly talked about customer data platforms in theory. It can be especially helpful to learn how other companies use their customer data platforms and the results they get from those tools.`,
}

export const CDPSuccessStories = () => {
  // Mobile breakpoints
  const theme = useTheme()
  const mobileResolutionMediaQuery = theme.breakpoints.down(`md`, false)
  const isSmallResolution = useMatchMedia(mobileResolutionMediaQuery, true)
  return (
    <Wrapper>
      <StyledSectionBase variant="centered">
        <StyledSectionCopy>
          <CopyTextBase centered={!isSmallResolution}>
            <CopyText.Heading tag="h2">{copy.heading}</CopyText.Heading>
            <CopyText.Description tag="p" variant="pLarge">
              {copy.description}
            </CopyText.Description>
          </CopyTextBase>
        </StyledSectionCopy>
        <Section.Contents>
          <TestimonialSlider data={testimonialData} title={false} innerStyles={false} />
        </Section.Contents>
      </StyledSectionBase>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding-bottom: 56px;
  ${({ theme }) => theme.breakpoints.down(`md`)} {
    padding-bottom: 0;
  }
`

const StyledSectionBase = styled(SectionBase)`
  max-width: 1280px;
  overflow: visible;
  ${({ theme }) => theme.breakpoints.down(`md`)} {
    ${Section.Wrapper} {
      row-gap: 40px;
    }
  }
`

const StyledSectionCopy = styled(Section.Copy)`
  max-width: 844px;
`

export default CDPSuccessStories
