import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["form", "status", "heading", "description", "tokens", "appearance"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { AppearanceProvider, useAppearance } from '@segment/matcha/foundations/AppearanceContext';
import { withDesignTokens } from '@segment/matcha/useDesignTokens';
import { data } from './data';
import * as views from './FormSubmitMessage.views';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";

var BaseFormSubmitMessage = function BaseFormSubmitMessage(_ref) {
  var form = _ref.form,
      status = _ref.status,
      heading = _ref.heading,
      description = _ref.description,
      tokens = _ref.tokens,
      externalAppearance = _ref.appearance,
      rest = _objectWithoutProperties(_ref, _excluded);

  var appearance = useAppearance(externalAppearance);
  var localData = form && data[status][form];
  return _jsx(AppearanceProvider, {
    appearance: appearance,
    children: _jsxs(views.Wrapper, _objectSpread(_objectSpread(_objectSpread({}, tokens.wrapper), rest), {}, {
      children: [heading && _jsx(views.Header, {
        children: _jsx(views.StyledTypography, {
          variant: "h4",
          tag: "p",
          tokens: tokens.header,
          children: heading
        })
      }), _jsx(views.StyledDescription, {
        variant: "pMedium",
        tokens: tokens.description,
        children: description || (localData === null || localData === void 0 ? void 0 : localData.description)
      })]
    }))
  });
};

export var FormSubmitMessage = withDesignTokens(BaseFormSubmitMessage, function () {
  return {};
}, {
  dark: function dark(theme) {
    return {
      wrapper: {
        borderColor: theme.palette.brand.darkAcai[300]
      },
      description: {
        color: theme.palette.brand.darkAcai[300]
      }
    };
  },
  light: function light(theme) {
    return {
      wrapper: {
        borderColor: theme.palette.brand.darkAcai[300]
      },
      description: {
        color: theme.palette.brand.darkAcai[500]
      }
    };
  },
  twilioLight: function twilioLight(theme) {
    return {
      wrapper: {
        borderColor: theme.palette.twilio.gray[30]
      },
      description: {
        color: theme.palette.twilio.gray[100]
      }
    };
  },
  twilioDark: function twilioDark(theme) {
    return {
      wrapper: {
        borderColor: theme.palette.twilio.gray[30]
      },
      description: {
        color: theme.palette.neutrals.white[100]
      }
    };
  }
});
export default FormSubmitMessage;