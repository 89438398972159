import type { FC, PropsWithChildren } from 'react'

import Section from '@segment/matcha/recipes/Section'

import LogoCarousel from '@segment/templates/LogoCarousel'

const LogoCarouselSection: FC<PropsWithChildren> = () => {
  return (
    <Section variant="noCopy" aria-label="Logo Carousel">
      <LogoCarousel />
    </Section>
  )
}

export default LogoCarouselSection
