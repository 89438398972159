import styled from '@emotion/styled'
import { useTheme } from '@emotion/react'

import Image from '@segment/matcha/components/Image'

import GoodUserExperiencesPng2x from './assets/GoodUserExperiences@2x.png'
import GoodUserExperiencesWebp from './assets/GoodUserExperiences.webp'
import GoodUserExperiencesWebp2x from './assets/GoodUserExperiences@2x.webp'

const GoodUserExperiencesImage = () => {
  const theme = useTheme()
  return (
    <picture>
      <source
        type="image/webp"
        media={theme.breakpoints.up(`lg`, false)}
        srcSet={`${GoodUserExperiencesWebp}, ${GoodUserExperiencesWebp2x} 2x`}
      />
      <StyledImage width="710" height="457" src={GoodUserExperiencesPng2x} alt="Illustration: Sources" loading="lazy" />
    </picture>
  )
}

const StyledImage = styled(Image)`
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
`

export default GoodUserExperiencesImage
