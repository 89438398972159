import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["heading", "logos", "appearance", "headingVariant"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { useAppearance } from '@segment/matcha/foundations/AppearanceContext';
import LogoCarouselBase, * as LogoCarousel from '@segment/matcha/recipes/LogoCarousel';
import { carouselLogos, carouselLogosMono } from './data';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";

var getDefaultLogos = function getDefaultLogos(logosMap) {
  return [logosMap.DIGITAL_OCEAN, logosMap.IBM, logosMap.DOMINOS, logosMap.ANHEUSER_BUSCH, logosMap.VMWARE, logosMap.LEVIS, logosMap.LOG_ME_IN, logosMap.CLEAR_SCORE, logosMap.VACASA, logosMap.STAPLES, logosMap.INTUIT, logosMap.THE_SKIMM, logosMap.DRIFT, logosMap.ALLERGAN, logosMap.GLOSSIER, logosMap.AB_IN_BEV];
};

var LogoCarouselTemplate = function LogoCarouselTemplate(_ref) {
  var _ref$heading = _ref.heading,
      heading = _ref$heading === void 0 ? "Join the 25,000+ companies using the Segment platform" : _ref$heading,
      externalLogos = _ref.logos,
      externalAppearance = _ref.appearance,
      headingVariant = _ref.headingVariant,
      rest = _objectWithoutProperties(_ref, _excluded);

  var appearance = useAppearance(externalAppearance);
  var finalLogos = externalLogos !== null && externalLogos !== void 0 ? externalLogos : getDefaultLogos(appearance === "twilioLight" ? carouselLogos : carouselLogosMono);
  return _jsxs(LogoCarouselBase, _objectSpread(_objectSpread({}, rest), {}, {
    children: [_jsx(LogoCarousel.Heading, {
      variant: headingVariant,
      children: heading
    }), _jsx(LogoCarousel.LogoTrack, {
      icons: finalLogos
    })]
  }));
};

export default LogoCarouselTemplate;