import styled from '@emotion/styled'

import SectionBase, * as Section from '@segment/matcha/recipes/Section'
import CopyTextBase, * as CopyText from '@segment/matcha/recipes/CopyText'

import getAbsoluteUrl from '@segment/utils/getAbsoluteUrl'

import { usePageConfig } from '@segment/contexts/page'

import SegmentOnCustomerDataImage from './SegmentOnCustomerDataImage'

const copy = {
  heading: (
    <>
      Proven and trusted <br /> at scale
    </>
  ),
  description: `Trusted by over 25,000 companies, we scale seamlessly across all business sizes with our high-capacity event streaming architecture, enabling you to manage and activate large data volumes instantly, without latency or manual intervention, even during unexpected spikes.`,
}

const link = {
  text: `Learn more`,
  url: `/customers/`,
}

const SegmentOnCustomerData = () => {
  const pageConfig = usePageConfig()

  return (
    <>
      <SectionComponent variant="2Col">
        <SectionCopy alignSelf="center">
          <CopyTextBase>
            <CopyText.Heading tag="h3">{copy.heading}</CopyText.Heading>
            <CopyText.Description tag="h3">{copy.description}</CopyText.Description>
            <CopyText.Link href={getAbsoluteUrl(link.url, pageConfig)}>{link.text}</CopyText.Link>
          </CopyTextBase>
        </SectionCopy>
        <Section.Contents>
          <SegmentOnCustomerDataImage />
        </Section.Contents>
      </SectionComponent>
    </>
  )
}

const SectionComponent = styled(SectionBase)`
  overflow-x: visible;
  justify-content: center;
  ${Section.Wrapper} {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    column-gap: 0;
    ${({ theme }) => theme.breakpoints.down(`md`)} {
      padding-right: 0;
      grid-template-areas:
        'contents'
        'copy';
    }
  }
`

const SectionCopy = styled(Section.Copy)`
  margin: 0 auto;
  max-width: 392px;
  ${({ theme }) => theme.breakpoints.down(`md`)} {
    margin-left: 0;
  }
`

export default SegmentOnCustomerData
