import styled from '@emotion/styled'

import ColumnTrack from '@segment/matcha/components/ColumnTrack'
import FeatureBoxBase, * as FeatureBox from '@segment/matcha/components/FeatureBox'

import SectionBase, * as Section from '@segment/matcha/recipes/Section'
import CopyTextBase, * as CopyText from '@segment/matcha/recipes/CopyText'
import TabsBase, * as Tabs from '@segment/matcha/recipes/Tabs'
import useTabs from '@segment/matcha/recipes/Tabs/useTabs'

import getAbsoluteUrl from '@segment/utils/getAbsoluteUrl/getAbsoluteUrl'

import type { PageConfig } from '@segment/contexts/page'
import { usePageConfig } from '@segment/contexts/page'

const copy = {
  heading: `Who needs a customer data platform?`,
  description: `A customer data platform should empower every team to drive action or insights.`,
}

const getData = ({ pageConfig }: { pageConfig: PageConfig }) => [
  {
    key: `executives`,
    title: `For Executives`,
    cards: [
      {
        badge: `01`,
        title: `Make data-driven decisions`,
        description: `Use customer insights to fuel better outcomes.`,
      },
      {
        badge: `02`,
        title: `Personalize user experiences`,
        description: `Tailor customer journeys to maximize value.`,
      },
      {
        badge: `03`,
        title: `Accelerate time to value`,
        description: `Easily launch new products and new markets.`,
      },
    ],
    linkHeader: `Learn about the Total Economic Impact of Segment’s customer data platform.`,
    linkText: `Access the guide`,
    linkUrl: `https://learn.segment.com/forrester-tei-report/`,
  },
  {
    key: `engineering`,
    title: `For Engineers`,
    cards: [
      {
        badge: `01`,
        title: `Easily collect data from everywhere`,
        description: `Use one API for all customer data collection.`,
      },
      {
        badge: `02`,
        title: `Integrate new tools in minutes`,
        description: (
          <>
            Skip time-consuming and redundant work with{` `}
            <a href={getAbsoluteUrl(`/catalog/`, pageConfig)}>pre-built integrations</a>.
          </>
        ),
      },
      {
        badge: `03`,
        title: `Activate warehouse data in all of your tools`,
        description: (
          <>
            Use <a href={getAbsoluteUrl(`/product/reverse-etl/`, pageConfig)}>Reverse ETL</a> to automatically sync data
            from your warehouse out to the tools you rely on to engage customers.
          </>
        ),
      },
    ],
    linkHeader: `Use one API to collect and activate data, across any platform.`,
    linkText: `Explore Segment for Engineering`,
    linkUrl: `/engineering/`,
  },
  {
    key: `marketing`,
    title: `For Marketers`,
    cards: [
      {
        badge: `01`,
        title: `Understand the full user journey`,
        description: `Connect everything online and offline.`,
      },
      {
        badge: `02`,
        title: `Build your own real-time audiences`,
        description: `Use one audience to power every tool.`,
      },
      {
        badge: `03`,
        title: `Power marketing attribution`,
        description: `Prove your direct impact on the business.`,
      },
    ],
    linkHeader: `Unify your customers’ touch points across all platforms and channels.`,
    linkText: `Explore Segment for Marketing`,
    linkUrl: `/marketing/`,
  },
  {
    key: `product`,
    title: `For Product`,
    cards: [
      {
        badge: `01`,
        title: `Make data access self-serve`,
        description: `Move faster without analytics bottlenecks.`,
      },
      {
        badge: `02`,
        title: `Build the best growth stack`,
        description: `Get new tools working in days not months.`,
      },
      {
        badge: `03`,
        title: `Measure and improve KPIs`,
        description: `Move the needle and prove your impact.`,
      },
    ],
    linkHeader: `Enable the best product analytics, A/B testing, and data warehouse tools with the flip of a switch.`,
    linkText: `Explore Segment for Product`,
    linkUrl: `/product/`,
  },
]

export const DataGovernanceAndControls = () => {
  const pageConfig = usePageConfig()
  const tabs = getData({ pageConfig })

  const { getTrackProps, getControlProps, getPanelProps, activeTabKey } = useTabs(
    0,
    tabs.length,
    `data-governance-control`
  )

  return (
    <StyledSectionBase variant="centered">
      <Section.Copy>
        <CopyTextBase centered={true}>
          <CopyText.Heading tag="h3">{copy.heading}</CopyText.Heading>
          <CopyText.Description tag="p">{copy.description}</CopyText.Description>
        </CopyTextBase>
      </Section.Copy>
      <Section.Contents>
        <TabsBase variant="filled">
          <TabsWrapper>
            <Controls {...getTrackProps()}>
              {tabs.map((tab, index) => (
                <Tabs.Control {...getControlProps(index)} key={tab.key}>
                  {tab.title}
                </Tabs.Control>
              ))}
            </Controls>

            {tabs.map((tab, index) => (
              <Slide {...getPanelProps(index)} active={index === activeTabKey} key={tab.title}>
                <StyledColumnTrack columns={1} overrides={{ sm: 2, lg: 3 }}>
                  {tabs[index].cards.map((card) => (
                    <FeatureBoxBase key={card.badge} alignment="left">
                      <FeatureBox.Title tag="h4" variant="h4">
                        {card.title}
                      </FeatureBox.Title>
                      <FeatureBox.Text tag="p" variant="pMedium">
                        {card.description}
                      </FeatureBox.Text>
                    </FeatureBoxBase>
                  ))}
                </StyledColumnTrack>
                <LinkWrapper>
                  <CopyTextBase centered={true}>
                    <CopyText.Description tag="p">{tabs[index].linkHeader}</CopyText.Description>
                    <CopyText.Link href={getAbsoluteUrl(tabs[index].linkUrl, pageConfig)}>
                      {tabs[index].linkText}
                    </CopyText.Link>
                  </CopyTextBase>
                </LinkWrapper>
              </Slide>
            ))}
          </TabsWrapper>
        </TabsBase>
      </Section.Contents>
    </StyledSectionBase>
  )
}

const StyledSectionBase = styled(SectionBase)`
  overflow-x: visible;
  justify-content: center;
  ${Section.Wrapper} {
    row-gap: 64px;
    ${({ theme }) => theme.breakpoints.down(`md`)} {
      row-gap: 32px;
      padding-top: 64px;
    }
  }

  ${({ theme }) => theme.breakpoints.down(`md`)} {
    padding-top: 0;
  }
`

const TabsWrapper = styled.div`
  display: grid;
  grid-template:
    'tabs' auto
    'slides' auto;
  gap: 56px;
  justify-items: center;
`

const LinkWrapper = styled.div`
  max-width: 750px;
  margin: 0 auto;
`

const Controls = styled(Tabs.Track)`
  grid-area: tabs;
`

const Slide = styled(Tabs.Panel)`
  grid-area: slides;
  display: grid;
  grid-template-rows: auto auto;
  position: relative;
  align-self: flex-start;
  grid-area: slides;
  align-items: center;

  ${({ theme }) => theme.breakpoints.down(`md`)} {
    row-gap: 48px;
  }

  ${({ theme }) => theme.breakpoints.down(`xs`)} {
    row-gap: 20px;
    max-width: 327px;
  }

  ${({ theme }) => theme.breakpoints.up(`lg`)} {
    column-gap: 120px;
    row-gap: 0;
  }
`

const StyledColumnTrack = styled(ColumnTrack)`
  padding-bottom: 80px;
  padding-left: 80px;
  padding-right: 80px;
  ${({ theme }) => theme.breakpoints.down(`md`)} {
    padding-bottom: 40px;
    padding-left: 0;
    padding-right: 0;
  }
`

export default DataGovernanceAndControls
