import getAbsoluteUrl from '@segment/utils/getAbsoluteUrl';

var getData = function getData(pageConfig) {
  return {
    logo: {
      src: "/images/SegmentLogoWhite.png",
      srcSet: "/images/SegmentLogoWhite@2x.png 2x",
      alt: "Twilio, Inc.",
      width: 300,
      height: 65
    },
    bottomLinks: [{
      url: "https://www.twilio.com/legal/privacy/",
      title: "Privacy policy"
    }, {
      url: "https://www.twilio.com/legal/tos/",
      title: "Terms of Service"
    }],
    mainLinks: [{
      title: "Products",
      links: [{
        url: getAbsoluteUrl("/product/connections/", pageConfig),
        title: "Connections"
      }, {
        url: getAbsoluteUrl("/product/protocols/", pageConfig),
        title: "Protocols"
      }, {
        url: getAbsoluteUrl("/product/unify/", pageConfig),
        title: "Unify"
      }, {
        url: getAbsoluteUrl("/product/twilio-engage/", pageConfig),
        title: "Twilio Engage"
      }, {
        url: getAbsoluteUrl("/customer-data-platform/", pageConfig),
        title: "Customer Data Platform",
        rel: "nofollow"
      }, {
        url: getAbsoluteUrl("/catalog/", pageConfig),
        title: "Integrations Catalog"
      }, {
        url: getAbsoluteUrl("/pricing/", pageConfig),
        title: "Pricing"
      }, {
        url: getAbsoluteUrl("/security/", pageConfig),
        title: "Security"
      }, {
        url: getAbsoluteUrl("/product/gdpr/", pageConfig),
        title: "GDPR"
      }]
    }, {
      title: "For Developers",
      links: [{
        url: "https://segment.com/docs/",
        title: "Documentation"
      }, {
        url: "https://docs.segmentapis.com/",
        title: "Segment API"
      }, {
        url: getAbsoluteUrl("/partners/developer-portal/", pageConfig),
        title: "Build on Segment"
      }, {
        url: getAbsoluteUrl("/opensource/", pageConfig),
        title: "Open Source"
      }, {
        url: getAbsoluteUrl("/engineering/", pageConfig),
        title: "Engineering Team"
      }]
    }, {
      title: "Company",
      links: [{
        url: "https://www.twilio.com/company/jobs/",
        title: "Careers"
      }, {
        url: getAbsoluteUrl("https://segment.com/blog/", pageConfig),
        title: "Blog"
      }, {
        url: getAbsoluteUrl("https://segment.com/newsroom/", pageConfig),
        title: "Press"
      }, {
        url: getAbsoluteUrl("/events/", pageConfig),
        title: "Events"
      }, {
        url: getAbsoluteUrl("/good-data-better-marketing/", pageConfig),
        title: "Podcast"
      }, {
        url: getAbsoluteUrl("/growth-center/", pageConfig),
        title: "Growth Center"
      }, {
        url: getAbsoluteUrl("/data-hub/", pageConfig),
        title: "Data Hub"
      }]
    }, {
      title: "Support",
      links: [{
        url: getAbsoluteUrl("/help/", pageConfig),
        title: "Help Center"
      }, {
        url: getAbsoluteUrl("/contact/", pageConfig),
        title: "Contact us"
      }, {
        url: getAbsoluteUrl("/resources/", pageConfig),
        title: "Resources"
      }, {
        url: getAbsoluteUrl("/recipes/", pageConfig),
        title: "Recipes"
      }, {
        url: getAbsoluteUrl("/services/", pageConfig),
        title: "Professional Services"
      }, {
        url: "https://segment.com/security/bulletins/",
        title: "Security Bulletins"
      }, {
        url: "https://segment.com/docs/",
        title: "Documentation"
      }, {
        url: "https://segment.com/release-notes/",
        title: "Release Notes"
      }, {
        url: getAbsoluteUrl("/partners/", pageConfig),
        title: "Become a Partner"
      }, {
        url: getAbsoluteUrl("/resources/cdp/", pageConfig),
        title: "Guide to Customer Data Platforms"
      }]
    }],
    copyright: "\xA9 ".concat(new Date().getUTCFullYear(), " Twilio Inc. All Rights Reserved.")
  };
};

export default getData;