import styled from '@emotion/styled'

import SectionBase, * as Section from '@segment/matcha/recipes/Section'
import CopyTextBase, * as CopyText from '@segment/matcha/recipes/CopyText'

import getAbsoluteUrl from '@segment/utils/getAbsoluteUrl'

import { usePageConfig } from '@segment/contexts/page'

import EverythingYouNeedToKnowImage from './EverythingYouNeedToKnowImage'

const copy = {
  heading: `Adapt to each customers' needs by unifying and activating their data in real time`,
  description: `We deliver a comprehensive CDP, featuring real-time event streaming, data warehouse integration, identity resolution, advanced audience building, journey orchestration, and AI capabilities, for dynamic customer engagement that ensures data governance and compliance.`,
}

const EverythingYouNeedToKnow = () => {
  const pageConfig = usePageConfig()
  return (
    <>
      <SectionComponent variant="2Col">
        <SectionCopy alignSelf="center">
          <CopyTextBase>
            <CopyText.Heading tag="h3">{copy.heading}</CopyText.Heading>
            <CopyText.Description tag="h3">{copy.description}</CopyText.Description>
            <CopyText.Link href={getAbsoluteUrl(`/`, pageConfig)}>Learn more</CopyText.Link>
          </CopyTextBase>
        </SectionCopy>
        <Section.Contents>
          <EverythingYouNeedToKnowImage />
        </Section.Contents>
      </SectionComponent>
    </>
  )
}

const SectionComponent = styled(SectionBase)`
  overflow-x: visible;
  justify-content: center;
  ${Section.Wrapper} {
    padding-top: 0;
    padding-bottom: 80px;
    padding-left: 0;
    column-gap: 0;
    ${({ theme }) => theme.breakpoints.down(`md`)} {
      padding-bottom: 30px;
      padding-right: 0;
      grid-template-areas:
        'contents'
        'copy';
    }
  }
`

const SectionCopy = styled(Section.Copy)`
  margin: 0 auto;
  max-width: 392px;
  ${({ theme }) => theme.breakpoints.down(`md`)} {
    margin-left: 0;
  }
`

export default EverythingYouNeedToKnow
