import { useTheme } from '@emotion/react'

import {
  IbmMonoLogo,
  DigitalOceanMonoLogo,
  PagerDutyMonoLogo,
} from '@segment/matcha/components/CompanyLogos/CompanyMono'

import { IbmLogo, DigitalOceanLogo, PagerDutyLogo } from '@segment/matcha/components/CompanyLogos/Company'

const testimonialData = () => {
  const theme = useTheme()
  return [
    {
      company: `ibm`,
      logo: <IbmMonoLogo color={theme.palette.twilio.gray[70]} />,
      logoActive: <IbmLogo />,
      logoName: `IBM`,
    },
    {
      company: `digital-ocean`,
      logo: <DigitalOceanMonoLogo color={theme.palette.twilio.gray[70]} />,
      logoActive: <DigitalOceanLogo />,
      logoName: `DigitalOcean`,
    },
    {
      company: `pager-duty`,
      logo: <PagerDutyMonoLogo color={theme.palette.twilio.gray[70]} />,
      logoActive: <PagerDutyLogo />,
      logoName: `PagerDuty`,
    },
  ]
}

export { testimonialData }
