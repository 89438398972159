import styled from '@emotion/styled'
import { useTheme } from '@emotion/react'

import Image from '@segment/matcha/components/Image'

import CleanAndOrganizeDataPng2x from './assets/CleanAndOrganizeData@2x.png'
import CleanAndOrganizeDataWebp from './assets/CleanAndOrganizeData.webp'
import CleanAndOrganizeDataWebp2x from './assets/CleanAndOrganizeData@2x.webp'

const CleanAndOrganizeDataImage = () => {
  const theme = useTheme()
  return (
    <picture>
      <source
        type="image/webp"
        media={theme.breakpoints.up(`lg`, false)}
        srcSet={`${CleanAndOrganizeDataWebp}, ${CleanAndOrganizeDataWebp2x} 2x`}
      />
      <StyledImage
        width="720"
        height="521"
        src={CleanAndOrganizeDataPng2x}
        alt="Illustration: Analytics track code"
        loading="lazy"
      />
    </picture>
  )
}

const StyledImage = styled(Image)`
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
`

export default CleanAndOrganizeDataImage
