import styled from '@emotion/styled'

import { useTheme } from '@emotion/react'

import FeatureBoxBase, * as FeatureBox from '@segment/matcha/components/FeatureBox'
import ColumnTrack from '@segment/matcha/components/ColumnTrack'

import SectionBase, * as Section from '@segment/matcha/recipes/Section'
import CopyTextBase, * as CopyText from '@segment/matcha/recipes/CopyText'

import useMatchMedia from '@segment/utils/useMatchMedia'

import getAbsoluteUrl from '@segment/utils/getAbsoluteUrl'

import { usePageConfig } from '@segment/contexts/page'

import ImproveDataProtectionImage from './ImproveDataProtectionImage'

const copy = {
  heading: `Improve data protection and privacy`,
  description: `With the advent of the General Data Protection Regulation (GDPR), the California Consumer Privacy Act (CCPA), and other data privacy laws, respecting your customers’ data privacy is more important than ever.`,
}

type ColData = {
  heading: string
  description: string
  url: string
}

const columnTrackData: ColData[] = [
  {
    heading: `How to protect customer data`,
    description: `The data governance strategy and data plan that you use alongside your CDP will help limit the data you collect to ensure that you’re only collecting data that’s vital to your business.`,
    url: `/resources/cdp/protect-customer-data/`,
  },
  {
    heading: `Customer data and the GDPR`,
    description: `GDPR legislation affects each company differently depending on how you collect customer data. Your CDP can help you take the next steps to prepare and manage customer data.`,
    url: `/academy/collecting-data/customer-data-and-the-gdpr/`,
  },
  {
    heading: `Automate data privacy`,
    description: `With the Segment Privacy Portal, you can automate your approach to keeping your customers' data private.`,
    url: `/product/privacy-portal/`,
  },
]

const ImproveDataProtection = () => {
  // Mobile breakpoints
  const theme = useTheme()
  const mobileResolutionMediaQuery = theme.breakpoints.down(`md`, false)
  const isSmallResolution = useMatchMedia(mobileResolutionMediaQuery, true)

  const pageConfig = usePageConfig()

  return (
    <>
      <SectionComponent variant="2Col">
        <SectionCopy alignSelf="center">
          <CopyTextBase>
            <CopyText.Heading tag="h3">{copy.heading}</CopyText.Heading>
            <CopyText.Description tag="h3">{copy.description}</CopyText.Description>
          </CopyTextBase>
        </SectionCopy>
        <Section.Contents>
          <ImproveDataProtectionImage />
        </Section.Contents>
      </SectionComponent>
      <StyledColumnTrack columns={1} overrides={{ lg: 3 }} center={false}>
        {columnTrackData.map(({ heading, description, url }) => (
          <StyledFeatureBoxBase alignment="left" key={heading} headerPosition="top">
            <FeatureBox.Body>
              <FeatureBox.Title>{heading}</FeatureBox.Title>
              <FeatureBox.Text>{description}</FeatureBox.Text>
              <StyledCopyTextLink href={getAbsoluteUrl(url, pageConfig)} size={isSmallResolution ? `small` : `medium`}>
                Learn more
              </StyledCopyTextLink>
            </FeatureBox.Body>
          </StyledFeatureBoxBase>
        ))}
      </StyledColumnTrack>
    </>
  )
}

const SectionComponent = styled(SectionBase)`
  overflow-x: visible;
  justify-content: center;

  ${Section.Wrapper} {
    padding: 0;
    column-gap: 0;
    ${({ theme }) => theme.breakpoints.down(`md`)} {
      grid-template-areas:
        'copy'
        'contents';
    }
  }
`

const SectionCopy = styled(Section.Copy)`
  margin: 0 auto;
  ${({ theme }) => theme.breakpoints.down(`md`)} {
    margin-left: 0;
  }
  ${({ theme }) => theme.breakpoints.up(`lg`)} {
    max-width: 392px;
  }
`

const StyledFeatureBoxBase = styled(FeatureBoxBase)`
  ${({ theme }) => theme.breakpoints.down(`md`)} {
    row-gap: 0;
  }
`

const StyledColumnTrack = styled(ColumnTrack)`
  align-items: flex-start;
  justify-content: center;
  ${({ theme }) => theme.breakpoints.down(`md`)} {
    row-gap: 32px;
  }
  ${({ theme }) => theme.breakpoints.up(`md`)} {
    padding-left: 80px;
    padding-right: 80px;
  }
`

const StyledCopyTextLink = styled(CopyText.Link)`
  ${({ theme }) => theme.breakpoints.down(`md`)} {
    margin-top: 8px;
    margin-bottom: 0;
  }
`

export default ImproveDataProtection
