import styled from '@emotion/styled'

import { useTheme } from '@emotion/react'

import { AppearanceProvider } from '@segment/matcha/foundations/AppearanceContext'

import Footer from '@segment/templates/Footer'
import Navigation from '@segment/templates/Navigation'
import GettingStarted from '@segment/templates/GettingStarted'

import Layout from '@segment/next-shared/layout'

import Hero from './components/Hero/Hero'

import GoodUserExperiences from './components/GoodUserExperiences'
import CreateMeaningfulInteractions from './components/CreateMeaningfulInteractions'
import HowCdpWorks from './components/HowCDPWorks'
import WhoNeedsAcdp from './components/WhoNeedsAcdp'
import BenefitsOfAcdp from './components/BenefitsOfACDP'
import HowToChooseAcdp from './components/HowToChooseAcdp'
import CdpSuccessStories from './components/CdpSuccessStories'
import LearnMoreAboutCustomerData from './components/LearnMoreAboutCustomerData'
import LogoCarouselSection from './components/LogoCarouselSection'

import ogImagePng from './assets/OgCustomerDataPlatform.png'

const CustomerDataPlatformView = () => {
  const theme = useTheme()
  return (
    <AppearanceProvider appearance="twilioLight">
      <Layout
        pageTitle="Customer Data Platform (CDP) | Twilio Segment"
        pageDescription="Learn why the best companies in the world use customer data platforms (CDPs) to drive action and insights."
        pageOG={ogImagePng}
        bodyBackground={theme.palette.neutrals.white[100]}
      >
        <Main>
          <Navigation />
          <Hero />
          <LogoCarouselSection />
          <GoodUserExperiences />
          <CreateMeaningfulInteractions />
          <HowCdpWorks />
          <WhoNeedsAcdp />
          <BenefitsOfAcdp />
          <HowToChooseAcdp />
          <CdpSuccessStories />
          <LearnMoreAboutCustomerData />
          <GettingStarted />
        </Main>
        <Footer />
      </Layout>
    </AppearanceProvider>
  )
}

const Main = styled.main`
  background-color: ${({ theme }) => theme.palette.neutrals.white[100]};
`

export default CustomerDataPlatformView
