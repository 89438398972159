import styled from '@emotion/styled'

import { useTheme } from '@emotion/react'

import FeatureBoxBase, * as FeatureBox from '@segment/matcha/components/FeatureBox'
import ColumnTrack from '@segment/matcha/components/ColumnTrack'

import SectionBase, * as Section from '@segment/matcha/recipes/Section'
import CopyTextBase, * as CopyText from '@segment/matcha/recipes/CopyText'

import useMatchMedia from '@segment/utils/useMatchMedia'

import getAbsoluteUrl from '@segment/utils/getAbsoluteUrl'

import { usePageConfig } from '@segment/contexts/page'

import BuildInsigtfulCustomerAnalyticsImage from './BuildInsigtfulCustomerAnalyticsImage'

const copy = {
  heading: `Build insightful customer analytics`,
  description: `When you understand customer behavior, you make better decisions for marketing, product, sales, and more. By using a customer data platform, it’s easy to set up robust customer analytics that every team can rely on.`,
}

type ColData = {
  heading: string
  description: string
  url: string
}

const columnTrackData: ColData[] = [
  {
    heading: `How to use customer data analysis for higher ROI`,
    description: `Without a CDP, your data is likely going to be unorganized and siloed, which makes it nearly useless for customer analytics.`,
    url: `/resources/cdp/customer-analytics/`,
  },
  {
    heading: `An introduction to multi-touch attribution`,
    description: `Attribution models help you determine which parts of your marketing campaigns are most responsible for bringing in new customers.`,
    url: `/academy/advanced-analytics/an-introduction-to-multi-touch-attribution/`,
  },
  {
    heading: `Take product usability to the next level`,
    description: `Customer analytics also help make your product more useful to your customers—which can reduce your churn risk and increase customer satisfaction.`,
    url: `/academy/advanced-analytics/take-your-product-usability-to-the-next-level/`,
  },
]

const BuildInsigtfulCustomerAnalytics = () => {
  // Mobile breakpoints
  const theme = useTheme()
  const mobileResolutionMediaQuery = theme.breakpoints.down(`md`, false)
  const isSmallResolution = useMatchMedia(mobileResolutionMediaQuery, true)

  const pageConfig = usePageConfig()

  return (
    <>
      <SectionComponent variant="2Col" reverse={true}>
        <SectionCopy alignSelf="center">
          <CopyTextBase>
            <CopyText.Heading tag="h2">{copy.heading}</CopyText.Heading>
            <CopyText.Description tag="h2">{copy.description}</CopyText.Description>
          </CopyTextBase>
        </SectionCopy>
        <Section.Contents>
          <BuildInsigtfulCustomerAnalyticsImage />
        </Section.Contents>
      </SectionComponent>
      <StyledColumnTrack columns={1} overrides={{ lg: 3 }} center={false}>
        {columnTrackData.map(({ heading, description, url }) => (
          <StyledFeatureBoxBase alignment="left" key={heading} headerPosition="top">
            <FeatureBox.Body>
              <FeatureBox.Title>{heading}</FeatureBox.Title>
              <FeatureBox.Text>{description}</FeatureBox.Text>
              <StyledCopyTextLink href={getAbsoluteUrl(url, pageConfig)} size={isSmallResolution ? `small` : `medium`}>
                Learn more
              </StyledCopyTextLink>
            </FeatureBox.Body>
          </StyledFeatureBoxBase>
        ))}
      </StyledColumnTrack>
    </>
  )
}

const SectionComponent = styled(SectionBase)`
  overflow-x: visible;
  justify-content: center;
  ${Section.Wrapper} {
    padding-top: 0;
    padding-bottom: 80px;
    padding-left: 0;
    padding-right: 0;
    column-gap: 0;
    ${({ theme }) => theme.breakpoints.down(`md`)} {
      padding-bottom: 0;
      grid-template-areas:
        'copy'
        'contents';
    }
  }
`

const SectionCopy = styled(Section.Copy)`
  margin: 0 auto;
  align-self: baseline;
  ${({ theme }) => theme.breakpoints.down(`md`)} {
    margin-left: 0;
  }
  ${({ theme }) => theme.breakpoints.up(`lg`)} {
    max-width: 392px;
  }
`

const StyledFeatureBoxBase = styled(FeatureBoxBase)`
  ${({ theme }) => theme.breakpoints.down(`md`)} {
    row-gap: 0;
  }
`

const StyledColumnTrack = styled(ColumnTrack)`
  align-items: flex-start;
  ${({ theme }) => theme.breakpoints.down(`md`)} {
    padding-top: 24px;
    row-gap: 32px;
  }
  ${({ theme }) => theme.breakpoints.up(`md`)} {
    padding-left: 80px;
    padding-right: 80px;
  }
`

const StyledCopyTextLink = styled(CopyText.Link)`
  ${({ theme }) => theme.breakpoints.down(`md`)} {
    margin-top: 8px;
    margin-bottom: 0;
  }
`

export default BuildInsigtfulCustomerAnalytics
