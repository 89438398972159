import type { FC, PropsWithChildren } from 'react'

import styled from '@emotion/styled'

import Divider from '@segment/matcha/components/Divider'

import SectionBase, * as Section from '@segment/matcha/recipes/Section'
import CopyTextBase, * as CopyText from '@segment/matcha/recipes/CopyText'

import CleanAndOrganizeData from './CleanAndOrganizeData'
import BuildInsigtfulCustomerAnalytics from './BuildInsigtfulCustomerAnalytics'
import ImproveDataProtection from './ImproveDataProtection'

const BenefitsOfACDP: FC<PropsWithChildren<unknown>> = () => {
  return (
    <StyledSectionBase variant="centered" copyVariant="narrow">
      <SectionCopy>
        <CopyTextBase centered={true}>
          <CopyText.Heading tag="h2">
            The benefits of a <br /> customer data platform
          </CopyText.Heading>
        </CopyTextBase>
      </SectionCopy>
      <ContentsStyled>
        <CleanAndOrganizeData />
        <Divider />
        <BuildInsigtfulCustomerAnalytics />
        <Divider />
        <ImproveDataProtection />
        <Divider />
      </ContentsStyled>
    </StyledSectionBase>
  )
}

const StyledSectionBase = styled(SectionBase)`
  ${Section.Wrapper} {
    padding-bottom: 20px;

    ${({ theme }) => theme.breakpoints.down(`md`)} {
      row-gap: 40px;
    }
  }
`

const SectionCopy = styled(Section.Copy)`
  margin: 0 auto;
  max-width: 844px;
`

const ContentsStyled = styled(Section.Contents)`
  display: grid;
  gap: 80px;
  ${({ theme }) => theme.breakpoints.down(`md`)} {
    row-gap: 40px;
  }
`

export default BenefitsOfACDP
