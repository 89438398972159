export var data = {
  success: {
    demo: {
      description: "Thank you for submitting your request for a demo! Answer 4 more questions to help us pinpoint exactly what your team needs to get started with Segment."
    }
  },
  error: {
    demo: {
      description: "We are really sorry. There was an error processing your submission. Try reloading your browser, and submitting again."
    }
  }
};